@import "~bootstrap/scss/bootstrap";
@import "../fonts/scss/ButlerFont.scss";
@import "../fonts/scss/Raleway.scss";
@import 'variables';

html {
  min-height: 100%;
  font-family: 'ralewaymedium', sans-serif;
}
.admin_login {
  background-image: url("/images/homepage_hero_2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  padding-top: 10vh;

  .container{
    height: 100%;
    align-content: center;
  }

  .card{
    height: 270px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0,0,0,0.5) !important;
  }

  .card-header h3{
    color: white;
  }

  .social_icon{
    position: absolute;
    right: 20px;
    top: -45px;
  }

  .input-group-prepend span{
    width: 50px;
    background-color: #FFC312;
    color: black;
    border:0 !important;
  }

  input:focus{
    outline: 0 0 0 0  !important;
    box-shadow: 0 0 0 0 !important;
  }

  .login_btn{
    color: $brown;
    background-color: $blue;
    width: 120px;

    &:hover{
      color: #fff;
      background-color: $brown;
    }
  }

  .links{
    color: white;

    a{
      margin-left: 4px;
    }
  }
}

.admin-section {
  nav {
    -webkit-box-shadow: 0px 0px 31px -11px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 0px 31px -11px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 0px 31px -11px rgba(0, 0, 0, 0.55);
    padding: 0 15px;
    background-color: #fff !important;

    .navbar-brand {
      max-width: 100px;
      padding: 0;
      margin-right: 25px;

      img {
        max-width: 100%;
      }
    }

    .nav-item {
      .nav-link {
        color: rgba(104, 96, 88, .7);
      }
    }
    .nav-item.active {
      .nav-link {
        color: rgba(104, 96, 88, 1);
      }
    }
    .btn-logout {
      background-color: #fff;
      border: 1px solid $blue;
      color: $blue;

      &:hover {
        -webkit-transition: background-color 250ms ease;
        -moz-transition: background-color 250ms ease;
        -ms-transition: background-color 250ms ease;
        -o-transition: background-color 250ms ease;
        transition: background-color 250ms ease;
        text-decoration: none !important;

        color: #fff;
        background-color: $blue;
      }
    }
  }

  h2 {
    color: $brown;
  }

  .btn-new {
    background-color: $brown;
    border: 1px solid $brown;
    color: #fff;

    &:hover {
      -webkit-transition: background-color 250ms ease;
      -moz-transition: background-color 250ms ease;
      -ms-transition: background-color 250ms ease;
      -o-transition: background-color 250ms ease;
      transition: background-color 250ms ease;
      text-decoration: none !important;

      color: $brown;
      background-color: #fff;
    }
  }

  .btn-edit {
    background-color: #fff;
    border: 1px solid $brown;
    color: $brown;

    &:hover {
      -webkit-transition: background-color 250ms ease;
      -moz-transition: background-color 250ms ease;
      -ms-transition: background-color 250ms ease;
      -o-transition: background-color 250ms ease;
      transition: background-color 250ms ease;
      text-decoration: none !important;

      color: #fff;
      background-color: $brown;
    }
  }

  .table .thead-light th {
    color: #fff;
    background-color: #243267;
    border-color: #243267;
  }

  #modal-delete {
    .btn-secondary {
      background-color: #fff;
      border: 1px solid $blue;
      color: $blue;

      &:hover {
        -webkit-transition: background-color 250ms ease;
        -moz-transition: background-color 250ms ease;
        -ms-transition: background-color 250ms ease;
        -o-transition: background-color 250ms ease;
        transition: background-color 250ms ease;
        text-decoration: none !important;

        color: #fff;
        background-color: $blue;
      }
    }
    .btn-primary {
      background-color: $brown;
      border: 1px solid $brown;
      color: #fff;

      &:hover {
        -webkit-transition: background-color 250ms ease;
        -moz-transition: background-color 250ms ease;
        -ms-transition: background-color 250ms ease;
        -o-transition: background-color 250ms ease;
        transition: background-color 250ms ease;
        text-decoration: none !important;

        color: $brown;
        background-color: #fff;
      }
    }
  }

  .up, .down {
    display: inline-block;
    width: 35px;
    background-color: $brown;
    border-radius: 4px;
    border: 0;

    img {
      max-width: 100%;
    }
  }
  //.up {
  //  float: left;
  //}
  //.down {
  //  float: right;
  //}

  .table tr:first-child .up{
    color:red;
    display: none;
  }
  .table tr:last-child .down{
    color:green;
    display: none;
  }
}


